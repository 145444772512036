var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "main",
    { staticClass: "drops_page detail_page", attrs: { id: "contents" } },
    [
      _c("h1", { staticClass: "page_ttl is_blind" }, [_vm._v("Drops Detail")]),
      _c("section", { staticClass: "overview_sec" }, [
        _c("div", {
          staticClass: "bg_el",
          style: `background-image: url(${_vm.artworkBackground})`,
        }),
        _c("div", { staticClass: "set_inner" }, [
          _c("header", { staticClass: "sec_head" }, [
            _c("div", { staticClass: "thumb_area" }, [
              _vm.artworkInfo.mimeType === "image/jpeg"
                ? _c("img", {
                    attrs: {
                      srcset: `${_vm.source_small} 590w, ${_vm.source_medium} 1920w, ${_vm.source} 3840w`,
                      src: _vm.source_small,
                      width: "890",
                      height: "500",
                      alt: _vm.artworkAlt ? _vm.artworkAlt : "",
                    },
                  })
                : _c(
                    "video",
                    {
                      staticStyle: { width: "auto" },
                      attrs: {
                        loop: "",
                        autoplay: "",
                        playsinline: "",
                        muted: "",
                        src: _vm.source,
                      },
                      domProps: { muted: true },
                    },
                    [
                      _vm._v(
                        " Sorry, your browser doesn't support embedded videos. "
                      ),
                    ]
                  ),
            ]),
            _c(
              "button",
              {
                staticClass: "btn type_ic btn_like",
                class: {
                  is_active: _vm.likeStatus,
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.likeThis(_vm.artworkId)
                  },
                },
              },
              [
                !_vm.likeStatus
                  ? _c("IcLike", {
                      ref: "icLike",
                      attrs: { status: !_vm.likeStatus, "icon-color": "#fff" },
                    })
                  : _c("IcLike", {
                      ref: "icLike",
                      attrs: { "icon-color": "#ff521d" },
                    }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "sec_body" }, [
            _c(
              "div",
              { staticClass: "ttl_wrap" },
              [
                _c("div", { staticClass: "ttl_area" }, [
                  _c("h3", { staticClass: "ttl" }, [
                    _vm._v(_vm._s(_vm.artworkInfo.title)),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "profile_info is_art",
                    on: {
                      click: function ($event) {
                        return _vm.goToProfile("2")
                      },
                    },
                  },
                  [
                    _vm.artistAvatarInfo && _vm.artistAvatarInfo.length
                      ? _c("span", {
                          staticClass: "profile",
                          style: `background-image: url(${_vm.artistAvatarInfo[0].resUrl})`,
                        })
                      : _c("IcProfile", { staticClass: "profile" }),
                    _c("IcRing", { staticClass: "ic_ring" }),
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.artworkInfo.artistName)),
                    ]),
                  ],
                  1
                ),
                _c("p", { staticClass: "edit" }, [
                  _vm._v("Edition of " + _vm._s(_vm.artworkInfo.totalEdition)),
                ]),
                _vm.artworkInfo.bcNwTp == "10" &&
                _vm.hdrWallet.walletYn === "N" &&
                _vm.isMob &&
                (_vm.openBlade || _vm.openWallypto)
                  ? [
                      _c("div", { staticClass: "input_area select drop_ui" }, [
                        _c(
                          "div",
                          { staticClass: "input_box drop_box" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "drop_btn has_arw",
                                class: { is_active: _vm.walletSelect },
                                attrs: {
                                  role: "combobox",
                                  "aria-haspopup": "listbox",
                                  "aria-controls": "dropWalletOpts",
                                  "aria-activedescendant": `wallet_op_${_vm.walletFocusOpt}`,
                                  "aria-expanded": _vm.walletSelect
                                    ? "true"
                                    : "false",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.walletSelect = !_vm.walletSelect
                                  },
                                },
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.walletOpt.label) + " "),
                                _vm.walletOpt.value === "WLT001"
                                  ? _c("b", { staticClass: "badge" }, [
                                      _vm._v("Popular"),
                                    ])
                                  : _vm._e(),
                                _c("ArwSelect", {
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ],
                              1
                            ),
                            _c("transition", { attrs: { name: "fade" } }, [
                              _vm.walletSelect
                                ? _c(
                                    "ul",
                                    {
                                      staticClass: "drop_op_list",
                                      attrs: { role: "listbox" },
                                    },
                                    _vm._l(
                                      _vm.walletOptData,
                                      function (opt, i) {
                                        return _c(
                                          "li",
                                          {
                                            key: "wallet_op_" + i,
                                            class: [
                                              {
                                                is_focus:
                                                  _vm.walletFocusOpt === i,
                                              },
                                              {
                                                is_active:
                                                  _vm.walletOpt.value ===
                                                  opt.value,
                                              },
                                            ],
                                            attrs: {
                                              id: "wallet_op_" + i,
                                              role: "option",
                                              "aria-selected": "false",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.walletOtp(opt, i)
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(opt.label)),
                                            ]),
                                            opt.value === "WLT001"
                                              ? _c(
                                                  "b",
                                                  { staticClass: "badge" },
                                                  [_vm._v("Popular")]
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _c("div", { staticClass: "buy_wrap float_el" }, [
              !false
                ? _c(
                    "div",
                    [
                      _vm.artworkInfo.dropsDivision == "live"
                        ? _c("CountDown", {
                            attrs: {
                              "date-time": _vm.artworkInfo.dropsEndDate,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "buy_group" }, [
                _c("div", { staticClass: "label_list_group" }, [
                  _c("dl", { staticClass: "label_list" }, [
                    _c("dt", [_vm._v("Blockchains")]),
                    _c("dd", {}, [
                      _c(
                        "p",
                        { staticClass: "blockchains" },
                        [
                          _vm.artworkInfo.bcNwTp == "10"
                            ? [_c("IcHederaS"), _c("b", [_vm._v("Hedera")])]
                            : [_c("IcEthS"), _c("b", [_vm._v("Ethereum")])],
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("dl", { staticClass: "label_list" }, [
                    _c("dt", [_vm._v("Price")]),
                    _c("dd", {}, [
                      !false
                        ? _c("p", { staticClass: "price" }, [
                            _vm.artworkInfo.bcNwTp == "10"
                              ? _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("setNum")(_vm.artworkInfo.price)
                                    ) + " USDC"
                                  ),
                                ])
                              : _c("b", [
                                  _vm._v(_vm._s(_vm.artworkInfo.price)),
                                  _c("span", { staticClass: "unit" }, [
                                    _vm._v("Ξ"),
                                  ]),
                                  _c("span", { staticClass: "dollars" }, [
                                    _vm._v(
                                      "(" +
                                        _vm._s(
                                          _vm._f("crtpyAbbreviate")(
                                            _vm.artworkInfo.priceToEthUsd
                                          )
                                        ) +
                                        ")"
                                    ),
                                  ]),
                                ]),
                          ])
                        : _c("p", { staticClass: "price" }, [
                            _c("b", [_vm._v("-")]),
                          ]),
                    ]),
                  ]),
                  _c("dl", { staticClass: "label_list" }, [
                    _vm._m(0),
                    _c("dd", {}, [
                      _c(
                        "p",
                        { staticClass: "price" },
                        [
                          _vm.accessToken && _vm.zipCodeCheck
                            ? [
                                _c("p", { staticClass: "price" }, [
                                  _vm.artworkInfo.bcNwTp == "10"
                                    ? _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("setNum")(_vm.taxOnly)
                                          ) + " USDC"
                                        ),
                                      ])
                                    : _c("b", [
                                        _vm._v(_vm._s(_vm.taxOnly)),
                                        _c("span", { staticClass: "unit" }, [
                                          _vm._v("Ξ"),
                                        ]),
                                        _c("span", { staticClass: "dollars" }, [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm._f("crtpyAbbreviate")(
                                                  _vm.artworkInfo.taxToEthUsd
                                                )
                                              ) +
                                              ")"
                                          ),
                                        ]),
                                      ]),
                                ]),
                              ]
                            : _vm.accessToken && !_vm.zipCodeCheck
                            ? [
                                _c("b", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "c_point",
                                      attrs: { href: "javascript:;" },
                                      on: { click: _vm.directUpdateInfo },
                                    },
                                    [_vm._v("Update your info")]
                                  ),
                                  _vm._v(" to check the sales tax estimate."),
                                ]),
                              ]
                            : !_vm.accessToken
                            ? [
                                _c("b", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "c_point",
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.loginPopup()
                                        },
                                      },
                                    },
                                    [_vm._v("Sign in")]
                                  ),
                                  _vm._v(" to check the sales tax estimate."),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ]),
                false
                  ? _c(
                      "button",
                      {
                        ref: "modalBtn",
                        staticClass: "btn w_f strong h_l",
                        on: {
                          click: function ($event) {
                            return _vm.openContactModal()
                          },
                        },
                      },
                      [_vm._v(" Contact Artist to Buy ")]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn w_f strong h_l",
                        class: { isDeactive: _vm.isLoading },
                        attrs: {
                          disabled:
                            _vm.getDropStatus == "Sold Out" ||
                            _vm.getDropStatus == "Ended" ||
                            _vm.getDropStatus == "Upcoming" ||
                            !_vm.openService,
                        },
                        on: { click: _vm.purchaseEvt },
                      },
                      [_vm._v(" " + _vm._s(_vm.getDropStatus) + " ")]
                    ),
              ]),
            ]),
            _c("div", { staticClass: "desc_wrap" }, [
              _c(
                "div",
                {
                  staticClass: "desc_group more_ui",
                  class: { is_more: _vm.showMoreDesc },
                },
                [
                  _c("h4", { staticClass: "ttl type_underL" }, [
                    _vm._v("Description"),
                  ]),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(_vm._s(_vm.artworkInfo.artworkDesc)),
                  ]),
                  _c(
                    "button",
                    {
                      staticClass: "btn type_txt has_arw dv_mob toggle_desc",
                      on: {
                        click: function ($event) {
                          _vm.showMoreDesc = !_vm.showMoreDesc
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.showMoreDesc ? "Close" : "More") + " "
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "tag_group" }, [
                _c("h4", { staticClass: "ttl type_underL" }, [_vm._v("Tag")]),
                _c(
                  "ul",
                  { staticClass: "tag_list clearFix" },
                  _vm._l(_vm.artworkInfo.keywords, function (tag, i) {
                    return _c(
                      "li",
                      { key: "tag_" + i, staticClass: "tag_node" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "tag_item",
                            on: {
                              click: function ($event) {
                                return _vm.goTagSearch(tag)
                              },
                            },
                          },
                          [_c("span", [_vm._v(_vm._s(tag))])]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "set_inner" }, [
        _c("section", { staticClass: "detail_sec detail_info_sec" }, [
          _c("header", { ref: "tabs", staticClass: "sec_head" }, [
            _c("div", { staticClass: "tab_wrap" }, [
              _c(
                "div",
                { staticClass: "tab_list", attrs: { role: "tablist" } },
                _vm._l(_vm.infoTabData, function (tab) {
                  return _c(
                    "div",
                    {
                      key: tab.id,
                      staticClass: "tab_node",
                      attrs: { role: "presentation" },
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "tab_item",
                          class: { is_active: _vm.currentTab === tab.id },
                          attrs: {
                            id: "tab_" + tab.id,
                            role: "tab",
                            "aria-controls": tab.id + "_panel",
                            "aria-selected":
                              tab.id === _vm.currentTab ? true : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.setTab(tab.id)
                            },
                          },
                        },
                        [
                          tab.icon === "time"
                            ? _c("IcTime", {
                                attrs: { "stroke-color": "#fff" },
                              })
                            : _vm._e(),
                          tab.icon === "items"
                            ? _c("IcItems", {
                                attrs: { "stroke-color": "#fff" },
                              })
                            : _vm._e(),
                          tab.icon === "eye"
                            ? _c("IcEye", { attrs: { "stroke-color": "#fff" } })
                            : _vm._e(),
                          tab.icon === "video"
                            ? _c("IcVideo", {
                                attrs: { "stroke-color": "#fff" },
                              })
                            : _vm._e(),
                          _c("span", [_vm._v(_vm._s(tab.name))]),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "sec_body tab_panel",
              attrs: {
                id: _vm.currentTab + "_panel",
                role: "tabpanel",
                "aria-labelledby": "tab_" + _vm.currentTab,
              },
            },
            [
              _vm.currentTab === "status"
                ? [
                    _c("div", { staticClass: "detail_info_wrap" }, [
                      _c("div", { staticClass: "detail_info_inner" }, [
                        _c("header", { staticClass: "detail_info_head" }, [
                          _c("ul", { staticClass: "status_list_head" }, [
                            _c("li", { staticClass: "history_info" }, [
                              _vm._v(
                                " " +
                                  _vm._s(/* artworkInfo.bcNwTp == '20' ? 'Market' : 'Buyer' */) +
                                  " Buyer "
                              ),
                            ]),
                            _c("li", { staticClass: "date_info" }, [
                              _vm._v("Date and Time"),
                            ]),
                            _c("li", { staticClass: "market_info" }, [
                              _vm.artworkInfo.bcNwTp == "20"
                                ? _c("span", [_vm._v("Market")])
                                : _vm._e(),
                            ]),
                            _c("li", { staticClass: "price_info" }, [
                              _vm._v("Price"),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "detail_info_body" }, [
                          _c(
                            "ul",
                            { staticClass: "status_list" },
                            _vm._l(_vm.statusInfos, function (status, i) {
                              return _c(
                                "li",
                                {
                                  key: "status_" + i,
                                  staticClass: "status_node",
                                },
                                [
                                  _c("div", { staticClass: "status_item" }, [
                                    _c("div", { staticClass: "user_info" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "profile_info",
                                          class: {
                                            is_art: status.artistYn === "Y",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToProfile(
                                                status.artistYn === "N"
                                                  ? "1"
                                                  : "2",
                                                status.buyerId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          status.buyerAvatarInfo &&
                                          status.buyerAvatarInfo.length > 0
                                            ? _c("span", {
                                                staticClass: "profile",
                                                style: `background-image: url(${status.buyerAvatarInfo[0].resUrl})`,
                                              })
                                            : _c("IcProfile", {
                                                staticClass: "profile",
                                                attrs: {
                                                  width: 50,
                                                  height: 50,
                                                  "stroke-width": ".7",
                                                },
                                              }),
                                          status.artistYn === "Y"
                                            ? _c("IcRing", {
                                                staticClass: "ic_ring",
                                                attrs: {
                                                  width: 50,
                                                  height: 50,
                                                  "small-ring": 14.8,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "txt_info" }, [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(_vm._s(status.buyerName)),
                                        ]),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "date_info" }, [
                                      _c("p", { staticClass: "date" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("UsaFormat")(
                                                _vm._f("GmtToTz")(
                                                  status.buyDate
                                                )
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "market_info" }, [
                                      _vm.artworkInfo.bcNwTp == "20"
                                        ? _c("span", [_vm._v("LG Art Lab")])
                                        : _vm._e(),
                                    ]),
                                    _c("div", { staticClass: "price_info" }, [
                                      _c("p", { staticClass: "price" }, [
                                        status.bcNwTp == "10"
                                          ? _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("setNum")(
                                                    status.buyPrice
                                                  )
                                                ) + " USDC"
                                              ),
                                            ])
                                          : _c("b", [
                                              _vm._v(_vm._s(status.buyPrice)),
                                              _c(
                                                "span",
                                                { staticClass: "unit" },
                                                [_vm._v("Ξ")]
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "dollars" },
                                                [
                                                  _vm._v(
                                                    " (" +
                                                      _vm._s(
                                                        _vm._f(
                                                          "crtpyAbbreviate"
                                                        )(status.priceToEthUsd)
                                                      ) +
                                                      ") "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                      ]),
                                    ]),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm.currentTab === "editions"
                ? [
                    _c(
                      "div",
                      { staticClass: "list_wrap detail_info_wrap" },
                      [
                        _c(
                          "header",
                          {
                            ref: "ulRef",
                            staticClass: "list_head detail_info_head",
                          },
                          [
                            _c("p", { staticClass: "count" }, [
                              _vm._v(" Edition of  "),
                              _c("span", { staticClass: "c_point" }, [
                                _vm._v(_vm._s(_vm.artworkInfo.totalEdition)),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "list_body detail_info_body" },
                          [
                            _c(
                              "ul",
                              {
                                staticClass: "grid grid_10 thumb_list ed_list",
                              },
                              _vm._l(_vm.editionItems, function (item, i) {
                                return _c(
                                  "li",
                                  {
                                    key: "edit_" + i,
                                    staticClass: "col col_3 thumb_node",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "thumb_item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.showFullScreen(i, item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "thumb_area" },
                                          [
                                            _c("button", [
                                              item.mimeType === "video/mp4"
                                                ? _c("img", {
                                                    attrs: {
                                                      srcset: `${item.videoThumbInfos[0].resUrl} 2x,
                      ${item.videoThumbInfos[1].resUrl}`,
                                                      src: `${item.videoThumbInfos[1].resUrl}`,
                                                      alt:
                                                        _vm.artworkInfo.title +
                                                        "Editions " +
                                                        item.editionId,
                                                    },
                                                  })
                                                : _c("img", {
                                                    attrs: {
                                                      srcset: `${item.imageInfos[0].resUrl} 2x, ${item.imageInfos[1].resUrl}`,
                                                      src: `${item.imageInfos[1].resUrl}`,
                                                      alt:
                                                        _vm.artworkInfo.title +
                                                        "Editions " +
                                                        item.editionId,
                                                    },
                                                  }),
                                              _c(
                                                "span",
                                                { staticClass: "is_blind" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.artworkInfo.title
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ic_area type_blockchains",
                                          },
                                          [_c("IcEth")],
                                          1
                                        ),
                                        _c("p", { staticClass: "edit" }, [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${i + 1} of ${
                                                    _vm.artworkInfo.totalEdition
                                                  }`
                                                ) +
                                                " Edition"
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                        _c("InfinityScroll", { on: { scroll: _vm.loadMore } }),
                      ],
                      1
                    ),
                  ]
                : _vm.currentTab === "docent"
                ? [
                    _c("DocentThumbSlide", {
                      attrs: { "item-data": _vm.docentThumbItems },
                      on: { setCurrent: _vm.setDocentCurrent },
                    }),
                    _c("div", { staticClass: "viewer_wrap type_img" }, [
                      _c("div", { staticClass: "img_area" }, [
                        _vm.currentDocent.item.thumbInfos[0].resUrl !==
                        undefined
                          ? _c("img", {
                              attrs: {
                                src: _vm.currentDocent.item.thumbInfos[0]
                                  .resUrl,
                                width: 1220,
                                alt: "currentDocent_Image",
                              },
                            })
                          : _c("img", {
                              attrs: {
                                src: _vm.docentInfo.imageInfos[0].resUrl,
                                width: 1220,
                                alt: "currentDocent_Image",
                              },
                            }),
                      ]),
                      _c("div", { staticClass: "desc_area" }, [
                        _c("p", [
                          _vm._v(_vm._s(_vm.currentDocent.item.content)),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm.currentTab === "film"
                ? [
                    _c("div", { staticClass: "viewer_wrap type_video" }, [
                      _c(
                        "div",
                        {
                          staticClass: "video_area",
                          staticStyle: { "text-align": "center" },
                        },
                        [
                          _c(
                            "video",
                            {
                              ref: "video",
                              attrs: {
                                id: "makingFilm",
                                "aria-label":
                                  "makingFilm for " + _vm.artworkInfo.title,
                              },
                              on: {
                                ended: function ($event) {
                                  _vm.isPlay = false
                                },
                              },
                            },
                            [
                              _c("source", {
                                attrs: {
                                  src: _vm.makingInfos[0].resUrl,
                                  type: "video/mp4",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "controls_area type_start" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn type_ic player",
                            on: { click: _vm.makingfilmPlayPause },
                          },
                          [
                            !_vm.isPlay
                              ? _c("IcPlayL", { staticClass: "ic_play" })
                              : _c("IcPauseL", { staticClass: "ic_pause" }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _vm.showQrModal
        ? _c("QrModal", {
            attrs: {
              "modal-id": "qrModal",
              "modal-data": _vm.purchaseData,
              showQrModal: _vm.showQrModal,
              dropId: _vm.dropId,
              artworkId: _vm.artworkId,
            },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _c("ContactArtistModal", {
        ref: "ContactArtistModal",
        on: { close: _vm.close },
      }),
      _c("SetPriceModal", {
        ref: "SetPriceModal",
        attrs: { "modal-size": "m" },
      }),
      _c("ConnectWalletModal", {
        attrs: { showWallet: _vm.showWallet },
        on: { close: _vm.close },
      }),
      _c("PurchaseProgressModal", {
        attrs: { showEthProgress: _vm.showEthProgress },
        on: { close: _vm.close },
      }),
      _c("InsufficientBalance", {
        attrs: {
          showUsdc: _vm.showUsdc,
          associatedSign: _vm.associatedSign,
          "modal-data": _vm.usdcModalInfo,
        },
        on: { close: _vm.close },
      }),
      _c("UpdateInfoModal", {
        ref: "UpdateInfoModal",
        attrs: { price: _vm.artworkInfo.price, selectId: _vm.selectArtworkId },
        on: { zipcodeSave: _vm.zipcodeSave },
      }),
      _vm.isShowDetailView
        ? _c("DetailViewer", {
            attrs: {
              collections: _vm.singleEdition,
              currentIndex: _vm.editionIdx,
              dropInfo: _vm.artworkInfo,
              isFullScreen: true,
            },
            on: { hide: _vm.hide },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("dt", [
      _vm._v("Estimated "),
      _c("br", { staticClass: "dv_mob" }),
      _vm._v("Tax"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }